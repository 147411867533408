.footer-container {
    position: relative;
    top: 90px;
    bottom: 0px;
    width: 100%;
    background-color: rgb(32, 48, 66);
    display: flex;

    flex-wrap: wrap;
    color: white;
    flex-direction: column;
    font-family: "Roboto", sans-serif;

    .secondary-container {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 10px;
        flex-wrap: wrap;

        .footer-logo {
            height: 136px;
            width: 273px;
            padding: 20px;
            margin: 5px 0px 0px 10px;
            background-color: white;
        }

        .quick-links {
            padding: 30px;


            .quick-head {
                padding: 10px 0px;
                font-weight: 600;
                font-size: 25px;
            }

            ul {

                li {
                    list-style: none;
                    padding: 5px 0px;
                    cursor: pointer;

                    a {
                        color: aliceblue;
                        text-decoration: none;
                    }

                    a:hover {
                        color: rgb(235, 235, 129);
                    }

                }
            }
        }

        .footer-contact {
            max-width: 300px;
            line-height: 25px;

            .quick-head {
                padding: 10px 0px;
                font-weight: 600;
                font-size: 25px;
            }

            .contact-footer {
                padding: 10px;
                background-color: transparent;
                border: 2px solid white;
                margin: 15px 0px 0px 0px;
                border-radius: 5px;

                a {
                    text-decoration: none;
                    color: white;
                    font-weight: 500;
                    padding: 5px;
                }
            }

            .contact-footer:hover {
                border: 2px solid rgb(235, 235, 129);
                color: rgb(228, 228, 174);
            }
        }
    }

    .reserved {
        width: 100%;
        background-color: rgb(13, 27, 46);
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        padding: 5px;
        font-size: 12px;

    }

}

@media screen and (min-width:100px) {
    .footer-container {
        .secondary-container {
            display: flex;
            flex-direction: column !important;

            .footer-contact {
                text-align: center !important;
            }
        }

    }

    .footer-container .secondary-container .footer-logo {
        height: 100px;
        width: 235px;
        padding: 20px;
        margin: 5px 0px 0px 10px;
        background-color: white;
    }
    .footer-container .secondary-container .footer-logo {
        height: 89px;
        width: 205px;
        padding: 20px;
        margin: 5px 0px 0px 10px;
        background-color: white;
    }


}
@media screen and (min-width:500px) {
    .footer-container .secondary-container .footer-logo {
        height: 100px;
        width: 235px;
        padding: 20px;
        margin: 5px 0px 0px 10px;
        background-color: white;
    }
}


@media screen and (min-width:700px) {
    .footer-container {
        .secondary-container {
            display: flex;
            flex-direction: row !important;

            .footer-contact {
                text-align: left !important;
            }
        }
        .footer-container .secondary-container .footer-logo {
            height: 136px;
            width: 273px;
            padding: 20px;
            margin: 5px 0px 0px 10px;
            background-color: white;
        }

    }


}