.container {
    min-height: 375px;
    width: 100%;
    background: linear-gradient(0deg, rgb(31 66 104) 0%, rgb(32, 48, 66) 100%);
    position: relative;
    top: 85px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;

    .home-grid-one {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        position: relative;
        top: 0px;

        .heading-container {

            .home-head-one {
                font-size: 40px;
                font-weight: 700;
                line-height: 70px;
                color: rgb(148, 104, 250);
            }

            .home-head-two {
                font-size: 25px;
                font-weight: 500;
                line-height: 53px;
                font-style: italic;
                color: rgb(241, 241, 241);

            }

            .home-head-three {
                font-size: 22px;
                font-weight: 300;
                color: rgb(247, 246, 246);
                line-height: 53px;
                font-style: italic;

            }
        }

    }

    .home-grid-two {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        margin-top: 25px;



        .home-contact-statement {
            display: flex;
            flex-direction: column;
            color: rgb(247, 246, 246);
            border-radius: 10px;
            color: rgb(15, 28, 68);
            background-color: rgb(245, 250, 255);
            align-items: center;


            .home-heading {

                align-items: center;
                font-size: 25px;
                font-weight: 600;
                line-height: 40px;
                color: rgb(74 74 74);
            }

            span:hover {
                color: rgb(241, 241, 158);
            }

            .enquiry {
                background-color: transparent;
                border: 4px solid rgb(120 82 211);
                color: rgb(120 82 211);
                padding: 15px 20px;
                font-size: 20px;
                font-weight: 700;
                border-radius: 10px;
                box-shadow: 0px 0px 1px;
                margin: 10px 0px;
                cursor: pointer;

            }
        }



    }




}

@media screen and (min-width:100px) {
    .container {
        grid-template-columns: 1fr;
        grid-template-rows: 0.8fr 0.2fr;
    }

    .container .home-grid-one .heading-container .home-head-one {

        font-size: 22px;
        font-weight: 700;
        line-height: 40px;
        color: rgb(148, 104, 250);
        margin-top: 28px;
    }

    .container .home-grid-one .heading-container .home-head-two {
        font-size: 15px;
        font-weight: 500;
        line-height: 37px;
        font-style: italic;
        color: rgb(241, 241, 241);
        padding-top: 18px;
    }

    .container .home-grid-one .heading-container .home-head-three {
        font-size: 17px;
        font-weight: 300;
        color: rgb(247, 246, 246);
        line-height: 30px;
        font-style: italic;
    }

    .container .home-grid-two .home-contact-statement .home-heading {
        align-items: center;
        font-size: 22px;
        font-weight: 600;
        line-height: 35px;
        color: rgb(74, 74, 74);

    }

    .container .home-grid-two .home-contact-statement {
        display: flex;
        flex-direction: column;
        color: rgb(247, 246, 246);
        border-radius: 10px;
        color: rgb(15, 28, 68);
        background-color: rgba(247, 250, 253, 0);
        align-items: center;


    }

    .container .home-grid-two .home-contact-statement .enquiry {
        background-color: transparent;
        border: 4px solid rgb(148, 104, 250);
        color: rgb(148, 104, 250);
        padding: 13px 16px;
        font-size: 14px;
        font-weight: 700;
        border-radius: 10px;
        margin: 10px 0px;
        cursor: pointer;
    }
}



@media screen and (min-width:420px) {
    .container {
        grid-template-columns: 1fr;
        grid-template-rows: 0.8fr 0.2fr;
    }

    .container .home-grid-one .heading-container .home-head-one {

        font-size: 23px;
        font-weight: 700;
        line-height: 40px;
        color: rgb(148, 104, 250);
        margin-top: 28px;
    }

    .container .home-grid-one .heading-container .home-head-two {
        font-size: 15px;
        font-weight: 500;
        line-height: 37px;
        font-style: italic;
        color: rgb(241, 241, 241);
        padding-top: 18px;
    }

    .container .home-grid-one .heading-container .home-head-three {
        font-size: 17px;
        font-weight: 300;
        color: rgb(247, 246, 246);
        line-height: 30px;
        font-style: italic;
    }

    .container .home-grid-two .home-contact-statement .home-heading {
        align-items: center;
        font-size: 25px;
        font-weight: 600;
        line-height: 35px;
        color: rgb(74, 74, 74);

    }

    .container .home-grid-two .home-contact-statement {
        display: flex;
        flex-direction: column;
        color: rgb(247, 246, 246);
        border-radius: 10px;
        color: rgb(15, 28, 68);
        background-color: rgba(247, 250, 253, 0);
        align-items: center;


    }

    .container .home-grid-two .home-contact-statement .enquiry {
        background-color: transparent;
        border: 4px solid rgb(148, 104, 250);
        color: rgb(148, 104, 250);
        padding: 13px 16px;
        font-size: 14px;
        font-weight: 700;
        border-radius: 10px;
        margin: 10px 0px;
        cursor: pointer;
    }
}

@media screen and (min-width:475px) {
    .container {
        grid-template-columns: 1fr;
        grid-template-rows: 0.8fr 0.2fr;
    }

    .container .home-grid-one .heading-container .home-head-one {

        font-size: 28px;
        font-weight: 700;
        line-height: 45px;
        color: rgb(148, 104, 250);
        margin-top: 28px;
    }

    .container .home-grid-one .heading-container .home-head-two {
        font-size: 20px;
        font-weight: 500;
        line-height: 45px;
        font-style: italic;
        color: rgb(241, 241, 241);
        padding-top: 18px;
    }

    .container .home-grid-one .heading-container .home-head-three {
        font-size: 23px;
        font-weight: 300;
        color: rgb(247, 246, 246);
        line-height: 30px;
        font-style: italic;
    }

    .container .home-grid-two .home-contact-statement .home-heading {
        align-items: center;
        font-size: 25px;
        font-weight: 600;
        line-height: 35px;
        color: rgb(74, 74, 74);

    }

    .container .home-grid-two .home-contact-statement {
        display: flex;
        flex-direction: column;
        color: rgb(247, 246, 246);
        border-radius: 10px;
        color: rgb(15, 28, 68);
        background-color: rgba(247, 250, 253, 0);
        align-items: center;


    }

    .container .home-grid-two .home-contact-statement .enquiry {
        background-color: transparent;
        border: 4px solid rgb(148, 104, 250);
        color: rgb(148, 104, 250);
        padding: 15px 20px;
        font-size: 22px;
        font-weight: 700;
        border-radius: 10px;

        margin: 10px 0px;
        cursor: pointer;
    }
}
@media screen and (min-width:600px) {
    .container {
        grid-template-columns: 1fr;
        grid-template-rows: 0.8fr 0.2fr;
    }

    .container .home-grid-one .heading-container .home-head-one {

        font-size: 35px;
        font-weight: 700;
        line-height: 50px;
        color: rgb(148, 104, 250);
        margin-top: 28px;
    }

    .container .home-grid-one .heading-container .home-head-two {
        font-size: 20px;
        font-weight: 500;
        line-height: 45px;
        font-style: italic;
        color: rgb(241, 241, 241);
        padding-top: 18px;
    }

    .container .home-grid-one .heading-container .home-head-three {
        font-size: 23px;
        font-weight: 300;
        color: rgb(247, 246, 246);
        line-height: 30px;
        font-style: italic;
    }

    .container .home-grid-two .home-contact-statement .home-heading {
        align-items: center;
        font-size: 25px;
        font-weight: 600;
        line-height: 35px;
        color: rgb(74, 74, 74);

    }

    .container .home-grid-two .home-contact-statement {
        display: flex;
        flex-direction: column;
        color: rgb(247, 246, 246);
        border-radius: 10px;
        color: rgb(15, 28, 68);
        background-color: rgba(247, 250, 253, 0);
        align-items: center;


    }

    .container .home-grid-two .home-contact-statement .enquiry {
        background-color: transparent;
        border: 4px solid rgb(148, 104, 250);
        color: rgb(148, 104, 250);
        padding: 15px 20px;
        font-size: 22px;
        font-weight: 700;
        border-radius: 10px;

        margin: 10px 0px;
        cursor: pointer;
    }
}