.navbar {
    height: 85px;
    padding-top: 10px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 11;
    position: fixed;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    .nav-logo {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-top: 7px;

        .nav-toggle-button {
            border: none;
            background-color: transparent;

            .hamburger {
                height: 50px;
                margin: 0px 30px;
            }

            .cross {
                height: 50px;
                margin: 0px 30px;
            }

        }

        .logo-image {
            height: 70px;
            width: 210px;
            margin: 0px 30px;
            cursor: pointer;
        }


    }

    .nav-links {
        display: none;

    }

    a {
        font-family: "Roboto", sans-serif;
        list-style: none;
        text-decoration: none;
        color: black;
    }
    a:hover{
        color: rgb(31, 86, 156);
    }
    #contact-buttons{
        font-family: "Roboto", sans-serif;
        color: white !important;
        font-weight: 500;
    }


    .nav-links.show-navbar-collapse {
        display: flex;
        height: 200px;
        width: 90%;
        background-color: #ededed;
        border-radius: 10px;



        ul {
            display: flex;
            flex-direction: column;
            list-style: none;
            font-weight: 500;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            padding: 5px;


            li {
                cursor: pointer;
                padding: 12px;

                .contact-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100px;
                    height: 30px;
                    padding: 10px;
                    border: none;
                    background: transparent;
                    background-color: rgb(20 53 216 / 83%);
                    color: rgb(0, 0, 0);
                    color: white;
                    border-radius: 4px;
                    cursor: pointer;

                }

                .contact-button:hover {
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                    background-color: rgb(124, 157, 218) !important;
                }
            }
        }
    }




}

@media screen and (min-width:900px) {
    .navbar {
        display: flex;
        flex-direction: row;
        height: 85px;

        .logo-image {
            height: 70px !important;
            width: 210px !important;
            cursor: pointer;
        }

        .nav-toggle-button {
            display: none;
        }

        .nav-links ul {
            display: flex;
            flex-direction: row !important;

        }



        .nav-links {
            display: flex !important;
            flex-direction: row;
            align-items: center;
            max-width: 500px;
            width: 100%;
            background-color: #fff !important;
            height: 70px !important;
            margin: 0px 30px;

            ul {
                display: flex;
                flex-direction: row;
                list-style: none;
                font-weight: 500;
                width: 100%;
                justify-content: space-between;
                align-items: center;

                li {
                    cursor: pointer;
                    padding: 2px;
                }
            }


            .contact-button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 30px;
                padding: 10px;
                border: none;
                background: transparent;
                background-color: rgb(20 53 216 / 83%);
                color: rgb(0, 0, 0);
                color: white;
                border-radius: 4px;
                cursor: pointer;

            }

            .contact-button:hover {
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            }
        }
    }
}