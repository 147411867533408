.product-container {

    width: 100%;
    background-color: rgba(236, 236, 236, 0.205);
    position: relative;
    top: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px 0px;
    padding: 30px 20px;
    text-align: center;

    .product-head {
        font-family: "Roboto", sans-serif;
        font-weight: 600;
        font-size: 45px;
        line-height: 60px;
        color: rgb(68, 68, 68);
    }

    .product-name {
        font-family: "Roboto", sans-serif;
        font-weight: 600;
        font-size: 40px;
        line-height: 60px;
        color: rgb(68, 68, 68);
    }

    .product-para {
        font-family: "Roboto", sans-serif;
        font-weight: 600;
        font-size: 20px;
        line-height: 45px;
        color: rgb(68, 68, 68);
    }

    .image-container {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 60%;
        line-height: 40px;

        .link {
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
            border-radius: 20px;
            margin: 30px auto;
        }

        img {
            height: 160px;
            width: 185px;
            border-radius: 20px;
            padding: 20px 20px 2px 20px;
        }


        a {
            text-decoration: none;

            color: rgb(68, 68, 68);
        }

        .visit-link {
            display: flex;
            justify-content: center;
            margin: 0px 0px 10px 0px;
            padding: 0px 1px 10px 1px;
            font-size: 15px;

        }

        .visit-link:hover {
            color: rgb(38, 80, 160);
        }
    }

    .scroll-container {
        display: flex;
        width: 90%;
        justify-content: flex-end;

        .scroll {
            height: 35px;
            width: 35px;
        }

    }

}

@media screen and (min-width:200px) {
    .product-container .product-head {
        font-family: "Roboto", sans-serif;
        font-weight: 600;
        font-size: 30px;
        line-height: 40px;
        color: rgb(68, 68, 68);
    }

    .product-container .image-container img {
        height: 90px;
        width: 100px;
        border-radius: 20px;
        padding: 10px 10px 2px 10px;
    }

    .product-container .image-container .visit-link {
        display: flex;
        justify-content: center;
        margin: 0px 0px 10px 0px;
        padding: 0px 1px 1px 1px;
        font-size: 15px;
    }

    .product-container .product-name {
        font-family: "Roboto", sans-serif;
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        color: rgb(68, 68, 68);
    }

    .product-container .product-para {
        font-family: "Roboto", sans-serif;
        font-weight: 600;
        font-size: 15px;
        line-height: 25px;
        color: rgb(68, 68, 68);
    }
}

@media screen and (min-width:600px) {
    .product-container .product-head {
        font-family: "Roboto", sans-serif;
        font-weight: 600;
        font-size: 45px;
        line-height: 60px;
        color: rgb(68, 68, 68);
    }

    .product-container .image-container img {
        height: 160px;
        width: 185px;
        border-radius: 20px;
        padding: 20px 20px 2px 20px;
    }

    .product-container .image-container .visit-link {
        display: flex;
        justify-content: center;
        margin: 0px 0px 10px 0px;
        padding: 0px 1px 10px 1px;
        font-size: 15px;
    }

    .product-container .product-name {
        font-family: "Roboto", sans-serif;
        font-weight: 600;
        font-size: 40px;
        line-height: 60px;
        color: rgb(68, 68, 68);
    }

    .product-container .product-para {
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 20px;
        line-height: 45px;
        color: rgb(68, 68, 68);
    }
}