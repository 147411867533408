.contact {
    max-width: 1000px;

    position: relative;
    top: 90px;
    color: rgb(68, 68, 68);
    margin: auto;
    display: flex;
    gap: 40px;
    align-items: center;
    padding: 50px 30px;
    margin: 10px auto;
 

    .text-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 30px;
        font-weight: 700;
        line-height: 30px;

        h1 {
            font-size: 40px;
            line-height: 60px;
        }
    }

    .form-container {
        flex: 1;
        padding: 2px;
        position: relative;

        .phone-svg {
            position: absolute;
            z-index: -1;

        }

        form {
            padding: 4px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            z-index: 2;

            input,
            textarea {
                background-color: transparent;
                padding: 20px;
                border: 2px solid rgb(33, 98, 196);
                border-radius: 10px;
                cursor: pointer;

            }

            .success {
                color: rgb(32, 179, 32);
            }

            .error {
                color: rgb(216, 79, 79);
            }

            button {
                padding: 15px;
                color: white;
                background-color: rgb(33, 98, 196);
                border: none;
                border-radius: 5px;
                cursor: pointer;
                font-size: 15px;
                font-family: "Roboto", sans-serif;
                font-weight: 500;
            }
        }
    }
}

@media screen and (min-width:200px) {
   
    .contact {
        display: flex;
        flex-direction: column !important;
    }
}

@media screen and (min-width:550px) {
    .contact {
        display: flex;
        flex-direction: row !important;
    }
}