.about-container {
    width: 90%;
    background-color: rgba(233, 233, 233, 0.267);
    position: relative;
    top: 90px;
    border-radius: 50px;
    
    margin: 50px auto;
    display: flex;
    justify-content: center;

    .text-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .about-head {
            
            margin: 0px auto;
            color: rgb(85, 85, 85);
            
        }

        .grid-container {
            display: grid;
            
            gap: 5px;
            height: 100%;
            width: 100%;

            .gridOne {
                display: flex;
                height: 100%;
                width: 100%;
                justify-content: center;
                .text{
                    
                    text-align: justify;
                   
                    color: rgb(73, 73, 73);
                    
                    font-weight: 500;
                }
            }

            .gridTwo {
                height: 100%;
                width: 100%;
                display: flex;
                
                justify-content: center;
                
            }
        }
    }
}

@media screen and (min-width:200px) {
    .grid-container{
        grid-template-rows: 1fr 0.5fr;
        grid-template-columns: 1fr;
    }
    .text{
        width: 65%;
        line-height: 18px;
        font-size: 13px;
    }
    .about-image{
        height: 150px;
        width: 190px;
        opacity: 0.85;
        display: flex;
        justify-content: center;
    }
    .about-head{
        padding: 20px 0px 5px 0px;
        font-size: 25px;
        padding: 30px 0px 20px 0px;
        
    }
   
}

@media screen and (min-width:400px) {
    .grid-container{
        grid-template-rows: 1fr 0.7fr;
        grid-template-columns: 1fr;
    }
    .about-image{
        height: 250px;
        width: 300px;
        opacity: 0.85;
        display: flex;
        justify-content: center;

    }
    
    .text{
        width: 65%;
        line-height: 30px;
        font-size: 13px;
    }
    .about-head{
        padding: 20px 0px 5px 0px;
        font-size: 25px;
        padding: 30px 0px 20px 0px;
    }
}

@media screen and (min-width:650px){
    .about-image{
        height: 250px;
        width: 300px;
        opacity: 0.85;
    }
    .text{
        padding: 35px 0px 30px 0px;
        line-height: 20px;
        font-size: 12px;
        padding: 30px 0px 30px 0px;
        width: 80%;
    }
    .about-head{
        padding: 30px 0px 5px 0px;
        font-size: 35px;
    }
    .grid-container{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
    }
    .gridTwo{
        align-items: center;
    }
}

@media screen and (min-width:700px){
    .text{
        padding: 35px 0px 30px 0px;
        line-height: 25px;
        font-size: 15px;
        padding: 30px 0px 30px 0px;
    }
}

@media screen and (min-width:900px){
    .about-image{
        height: 300px;
        width: 400px;
        opacity: 0.85;
    }
    .text{
        padding: 30px 0px 30px 0px;
        line-height: 30px;
        font-size: 15px;
    }
    .about-container{
        padding: 10px 5px;
    }
    .about-head{
        font-size: 40px;
    }
}

@media screen and (min-width:1100px){
    .about-image{
        height: 400px;
        width: 535px;
        opacity: 0.85;
    }
    .text{
        padding: 40px 0px 40px 0px;
        line-height: 30px;
        font-size: 18px;
    }
    .about-container{
        padding: 20px 20px;
    }
    
}